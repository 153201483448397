import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';
import { _x } from '@wordpress/i18n';

domReady(() => {
  const carouselBlocks = document.querySelectorAll(
    '.health-guide-featured-posts'
  );

  if (0 < carouselBlocks.length) {
    carouselBlocks.forEach((carouselBlock) => {
      const carousel = carouselBlock.querySelector('.swiper');

      if (carousel) {
        new Swiper(carousel, {
          slidesPerView: 1.1,
          spaceBetween: 12,
          loop: true,
          centerInsufficientSlides: true,
          breakpoints: {
            600: {
              slidesPerView: 2.2,
              spaceBetween: 12,
            },
            1080: {
              slidesPerView: 3.2,
              spaceBetween: 24,
            },
            1320: {
              slidesPerView: 3,
              spaceBetween: 24,
            },
          },
          a11y: {
            firstSlideMessage: _x(
              'This is the first slide',
              'carousel first slide label',
              'vtx'
            ),
            lastSlideMessage: _x(
              'This is the last slide',
              'carousel first slide label',
              'vtx'
            ),
            paginationBulletMessage: _x(
              'Go to slide {{index}}',
              'carousel navigation label',
              'vtx'
            ),
            prevSlideMessage: _x(
              'Previous slide',
              'carousel previous label',
              'vtx'
            ),
            nextSlideMessage: _x('Next slide', 'carousel next label', 'vtx'),
          },
        });
      }
    });
  }
});
